/* eslint-disable no-unused-vars */
import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../services/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [signed, setSigned] = useState(false);
  const [basePath, setBasePath] = useState(null);

  useEffect(() => {
    const storagedUser = sessionStorage.getItem('@EVAPI:user');
    const storagedToken = sessionStorage.getItem('@EVAPI:token');
      
    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
      setSigned(true)
    } else {
      setSigned(false)
    }

  }, []);


  async function Login(userData) {
    const response = await api.post('/auth/local', userData);
    setUser(response.data.user.username);
    setSigned(true)
    api.defaults.headers.Authorization = `Bearer ${response.data.jwt}`;
    sessionStorage.setItem('@EVAPI:user', JSON.stringify(response.data.user.username));
    sessionStorage.setItem('@EVAPI:permissions', JSON.stringify(response.data.user.permissions));
    localStorage.setItem('back', `dashboard`) 
    sessionStorage.setItem('@EVAPI:token', response.data.jwt);

    return response
  }

  function Logout() {
    setUser(null);
    sessionStorage.removeItem('@EVAPI:permissions');
    localStorage.removeItem('back')
    api.defaults.headers.Authorization = null
    sessionStorage.removeItem('@EVAPI:user');
    sessionStorage.removeItem('@EVAPI:token');
  }

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, Login, Logout, basePath, setBasePath }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
export default AuthContext;
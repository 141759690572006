import axios from "axios";
import { getToken } from "./auth";
const token = getToken();

const getApiBaseUrl = () => {
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  const prefix = pathSegments[0];

  switch (prefix) {
    case "apeprev":
      return "https://apeprev.sempereventos.com.br";
    case "agip":
      return "https://api.sempereventos.com.br";
  }
};

export const baseURL = getApiBaseUrl();

const api = axios.create({
  baseURL: baseURL + '/api',
});

api.interceptors.request.use(async config => {
  if (token) {
    config.headers.Authorization = "Bearer " + token + "";
  }
  return config;
});
export default api;
import React from 'react';
import { Outlet } from 'react-router-dom';

const LayoutWrapper = () => {

  return <Outlet />;
};



export default LayoutWrapper;

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, Stack, Snackbar, } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import TableInfo from '../../components/TableInfo'
import Add from '@mui/icons-material/Add'
import { useEvent } from '../../contexts/event.context';
import api from '../../services/api';
import XLSX from 'xlsx'


export default function Evento() {
    const { events, getEvents, deleteEvent } = useEvent()
    const navigate = useNavigate()
    const [stateAlert, setStateAlert] = React.useState({ status: false, type: 'success', msg: '' });


    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    async function handleEditEvent(eventId) {
        try {
            const event = await api.get('/events/' + eventId)
            if (event.status === 200) {
                navigate(`/event/edit/${eventId}`)
            }
        } catch (error) {
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `${error.message}` })
        }
    }
    function AddEvent() {
        navigate(`/event/add`)
  
    }

    async function listAttendance({ id }) {
        try {
            const list = await api.get('/events/export/' + id)
            if (list.status === 200) {
                if (list.data.response.length > 0) {
                    let sheetData = []
                    list.data.response.map(subs => {
                        subs.subscribers.map(item => {
                            sheetData.push({ nome: String(item.name).toLocaleUpperCase(), cpf: item.document })
                        })
                    })
                    const sheet = XLSX.utils.json_to_sheet(sheetData);
                    XLSX.utils.sheet_add_aoa(sheet, [list.data.columns], { origin: "A1" });
                    const max_width = sheetData.reduce((w, r) => Math.max(w, r.nome.length), 30);
                    sheet["!cols"] = [{ wch: max_width }];
                    const workBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workBook, sheet, 'lista-de-presença');
                    return await XLSX.writeFile(workBook, 'lista-de-presença.xlsx')
                }
                setStateAlert({ ...stateAlert, status: true, type: 'warning', msg: `Sem dados para gerar lista` })
            }
        } catch (error) {
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `${error.message}` })
        }
    }

    useEffect(() => {
        async function handleData() {
            await getEvents()
        }
        handleData()
        return () => {
            console.log('canceled')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const columns = [
        { Header: "Evento", accessor: "name", width: "35%" },
        { Header: "Inscritos", accessor: "count_subscribers", width: "15%" },
        { Header: "Ativo", accessor: "isActive", width: "25%" },
        { Header: "Encerramento de Inscrições", accessor: "end_subscription", width: "10%" },
        { Header: "Máximo de Inscrições", accessor: "max_subscribers", width: "5%" },
        { Header: "Ações", accessor: "actions", width: "10%" },
    ];
    return (
        <Box sx={{ width: '100%', height: '100vh' }} p={3}>
            <Snackbar
                open={stateAlert.status}
                autoHideDuration={2500}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setStateAlert({ status: false, type: 'success', msg: '' })} >
                <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                    {stateAlert.msg}
                </Alert>
            </Snackbar>
            <Stack direction="row" alignItems="center" justifyContent={'flex-end'} spacing={2} mx={9}>
                <Grid item xs={3} >
                    <Button variant="contained" onClick={AddEvent} startIcon={<Add />}>Adicionar Evento</Button>
                </Grid>
            </Stack>
            <Grid container p={3}>
                <TableInfo title='Eventos' edit={handleEditEvent} deactive={deleteEvent} list={listAttendance} variant="success" rows={events} columns={columns} />
            </Grid>
        </Box>
    )
}
import React from "react"
import {Grid, Typography, Box, Button, TextField, Alert, Snackbar, CircularProgress} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { CompanyContext } from "../../../contexts/CompanyContext";
import { FormContext } from "../../../contexts/FormContext";
import { OrderContext } from '../../../contexts/OrderContext';
import { useSubscriber } from '../../../contexts/SubscribersContext';
import { useEvent } from '../../../contexts/EventContext';

import api from '../../../services/api'

export default function FinsihRegistration(){
  const { company } = React.useContext(CompanyContext);
  const { order, setOrder } = React.useContext(OrderContext)
  const { activeStep, setActiveStep } = React.useContext(FormContext);
  const { subscriber, setSubscriber} = useSubscriber()
  const [loading, setLoading ] = React.useState(false )
  const { events, setEvents } = useEvent()
  const [stateAlert, setStateAlert] = React.useState({status:false, type: 'success', msg:''});

  const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const total = order.plan_price * order.quantity

  async function handleSubscription(){
      try{
        setLoading(true)
        const sendOrder = await api.post('/orders', { data: { 
                    event: order.event, 
                    company: order.company, 
                    plan: order.plan,
                    plan_price: order.plan_price,
                    subscribers: order.subscribers,
                    quantity: order.quantity,
                    payment_form: order.payment_form
                } 
              })
          setOrder([{...order, payment_url: sendOrder.data.payment_url}])
          console.log(sendOrder.status, sendOrder.data)
          setLoading(false)
          if(sendOrder.data.payment_url !== undefined || sendOrder.data.payment_url !== null) {
            window.open(sendOrder.data.payment_url)
          }
          setActiveStep(activeStep + 1)

      }catch(error){
        console.log(error)
        setLoading(false)
        setStateAlert({status:true, type: 'error', msg:`${error.response.data.error.message || error.message}`})
      }

  }
    return( 
    <React.Fragment>
      <Snackbar
                open={stateAlert.status}
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                onClose={() => setStateAlert({status: false, type: 'error', msg:'' } )} >
                    <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                        {stateAlert.msg}
                    </Alert>
            </Snackbar>
        <>
        {loading ? 
          <Box sx={{ width:'100%', height: '100vh',display: 'flex', justifyContent:'center' }}>
            <CircularProgress sx={{ alignSelf:'center'}} size={100}/>
         </Box> 
        :
        <>
        <Typography sx={{textAlign:'center' }} variant="h6">Instituição Pagadora </Typography>
        <Grid key={company.social_reason} container spacing={2} mt={2} mb={2} columns={12}>
        <Grid item xs={12} md={8}>
          <TextField
            id="social_reason"
            label="Razão Social"
            fullWidth
            variant="outlined"
            name="social_reason"
            disabled
            value={company.social_reason}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="cnpj"
            label="CNPJ"
            fullWidth
            variant="outlined"
            name="cnpj"
            disabled
            value={company.document}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            id="trade"
            label="Nome Fantasia"
            fullWidth
            variant="outlined"
            name="trade"
            disabled
            value={company.trade}
          />
        </Grid>
    
        <Grid item xs={12} md={4}>
          <TextField
            id="email"
            label="Email"
            fullWidth
            variant="outlined"
            name="email"
            disabled
            value={company.email}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="cep"
            label="CEP"
            fullWidth
            type={'tel'}
            variant="outlined"
            name="cep"
            disabled
            value={company.cep}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="address"
            label="Endereço"
            fullWidth
            variant="outlined"
            name="address"
            disabled
            InputLabelProps={{ shrink: true }}
            value={company.address}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="number"
            label="Número"
            fullWidth
            variant="outlined"
            name="number"
            InputLabelProps={{ shrink: true }}
            value={company.number}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="complement"
            label="Complemento"
            fullWidth
            variant="outlined"
            name="complement"
            InputLabelProps={{ shrink: true }}
            disabled
            value={company.complement}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="district"
            label="Bairro"
            fullWidth
            variant="outlined"
            name="district"
            InputLabelProps={{ shrink: true }}
            disabled
            value={company.district}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="city"
            label="Cidade"
            fullWidth
            variant="outlined"
            name="city"
            InputLabelProps={{ shrink: true }}
            disabled
            value={company.city}
        />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField
            id="state"
            label="Estado"
            fullWidth
            variant="outlined"
            name="state"
            InputLabelProps={{ shrink: true }}
            value={company.state}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="phone"
            label="Telefone"
            type={'tel'}
            fullWidth
            variant="outlined"
            name="phone"
            disabled
            value={company.phone}
          />
        </Grid>
        </Grid>
        <Typography sx={{textAlign:'center' }} variant="h6">Tipo de Inscrição </Typography>
        <Grid container spacing={2} mt={2} mb={2} columns={12}>
            <Grid item xs={12} md={12}>
              <TextField
                id="type_subscription"
                label="Tipo de Inscrição"
                fullWidth
                variant="outlined"
                name="phone"
                disabled
                value={order.plan_describe}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="payment_form"
                label="Forma de Pagamento"
                fullWidth
                variant="outlined"
                name="payment_form"
                disabled
                value={order.payment_form}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="quantity"
                label="Quantidade de Inscritos"
                fullWidth
                variant="outlined"
                name="quantity"
                disabled
                value={order.quantity}
              />
            </Grid>
        </Grid>
        <Typography sx={{textAlign:'center' }} variant="h6">Inscritos</Typography>
         <Grid container spacing={2} mt={2} mb={2} columns={12}>
          {subscriber.map((sub,index) => {
            return(
              <>
          <Grid key={index} item xs={12} md={2}>
                        <TextField
                          variant="outlined"
                          label="Nome Completo"
                          name="nome"
                          fullWidth
                          value={sub.name}
                          disabled
                        />
                        </Grid>
                        <Grid key={index} item xs={12} md={1}>
                        <TextField
                          variant="outlined"
                          label="Nome p/ Cracha"
                          name="badge"
                          fullWidth
                          value={sub.badge}
                          disabled
                        />
                        </Grid>
                        <Grid item xs={12} md={2}>
                              <TextField
                                 variant="outlined"
                                 label="CPF"
                                 name="document"
                                 fullWidth
                                 value={sub.document}
                                 disabled
                                />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                                   variant="outlined"
                                   label="Data de Nascimento"
                                   name="birth"
                                   fullWidth
                                   type="date"
                                   value={sub.birth}
                                   disabled
                                />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                                   variant="outlined"
                                   label="Email"
                                   name="email"
                                   fullWidth
                                   value={sub.email}
                                   disabled
                                />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                  variant="outlined"
                                  label="Whatsapp"
                                  name="whatsapp"
                                  fullWidth
                                  value={sub.whatsapp}
                                  disabled
                                />
                        </Grid>
                        <Grid item xs={12} md={1}>
                                <TextField
                                  variant="outlined"
                                  label="Cargo"
                                  name="office"
                                  fullWidth
                                  value={sub.office}
                                  disabled
                                  />
                        </Grid>
                        </>
                        )}
          )}
        </Grid>
        <Typography sx={{textAlign:'center' }} variant="h6">Total </Typography>
        <Grid container spacing={2} mt={2} mb={2} columns={12}>
            <Grid item xs={12} md={4}>
              <TextField
                id="quantity"
                label="Quantidade de Inscritos"
                fullWidth
                variant="outlined"
                name="quatity"
                disabled
                value={order.quantity}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="price"
                label="Valor por Inscrito"
                fullWidth
                variant="outlined"
                name="price"
                disabled
                value={`R$ ${order.plan_price}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="total"
                label="Valor Total"
                fullWidth
                variant="outlined"
                name="total"
                disabled
                value={`R$ ${total}`}
              />
            </Grid>
        </Grid>
        </>
        }
      </>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              type={'button'}
              color='warning'
              disabled={loading}
              onClick={() => setActiveStep(activeStep -1)}
              sx={{ mt: 3, mr: 1  }}>
                Voltar
              </Button>
              <Button
                variant="contained"
                ype={'submit'}
                color='success'
                disabled={loading}
                onClick={() => handleSubscription()}
                sx={{ mt: 3, mr: 1  }}>
                   {loading ? 'Gerar Cobrança' :'Realizar Cobrança'}
                </Button>
        </Box>
        
      </React.Fragment>)
}
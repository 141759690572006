import * as React from 'react';
import * as yup from 'yup';

import {Grid, Typography, TextField, Box, Button} from '@mui/material';

import { FormContext } from '../../../contexts/FormContext';
import { OrderContext } from '../../../contexts/OrderContext';
import { useSubscriber } from '../../../contexts/SubscribersContext';

import { validateBr } from 'js-brasil';
import { maskCPF2, maskTEL } from '../../../utils/mask';
import { FieldArray, Form, Formik, getIn } from "formik";
import { subYears, format } from 'date-fns';


export default function AddSubscriber() {
  const { activeStep, setActiveStep } = React.useContext(FormContext);
  const { order, setOrder } = React.useContext(OrderContext)
  const subscribers = []
  let load = false
  const {setSubscriber} = useSubscriber()
  
  function addFields(){
    for(let i = 1; i <= order.quantity; i++){
      subscribers.push({document: "", name: "", badge: "", whatsapp: "", email: "", office: "", birth: ""})
    }
  }

  yup.addMethod(yup.array, 'unique', function (field, message) {
    return this.test('unique', message, function (array) {
      const uniqueData = Array.from(
        new Set(array.map((row) => row[field]?.toLowerCase())),
      );
      const isUnique = array.length === uniqueData.length;
      if (isUnique) {
        return true;
      }
      const index = array.findIndex(
        (row, i) => row[field]?.toLowerCase() !== uniqueData[i],
      );
      if(array[index] === undefined){
        return this.createError({
          path: `${this.path}.${index}.${field}`,
          message: `${this.path}.${index}.${field} precisa ser preenchido`,
        });
      }
      if (array[index][field] === '') {
        return true;
      }
      return this.createError({
        path: `${this.path}.${index}.${field}`,
        message
      });
    });
  });

  const subscriberValidate = yup.object().shape({
    subscribers: yup.array().of(yup.object().shape({
        document: yup.string()
          .test("is-cnpj","CPF Inválido", value => validateBr.cpf(value || " "))
          .required('CPF precisa ser preenchido'),
        name: yup.string()
          .min(8, 'Nome inválido (muito curto)')
          .required('Nome precisa ser preenchido'),
        badge: yup.string()
          .optional(),
        email: yup.string()
          .email('E-mail inválido')
          .required('Email precisa ser preenchido'),
        whatsapp: yup.string()
          .min(15, 'Celular inválido')
          .required('Whatsapp precisa ser preenchido'),
        birth: yup.date('Data inválida')
          .max(format(subYears(new Date, 17 ), "yyyy-MM-dd"), 'Menor de 18 anos')
          .min(format(subYears(new Date, 100 ), "yyyy-MM-dd"), 'Maior de 100 anos')
          .required('Data de Nascimento precisa ser preenchida'),
        office: yup.string()
          .min(4,'Minimo 4 letras')
          .required('Cargo precisa ser preenchido')
    }))
    .unique('document', 'CPF não pode ser repetido.')
    .unique('name', 'Nome não pode ser repetido.')
    
  })   

function maskDocument(e){
  if(e.target.value.length > 14){ return }
  if(e.target.value.length > 0 ){ return  maskCPF2(e.target.value) }
  return e.target.value
}



React.useMemo(()=>{
  if(!load){
  if(order?.quantity !== order.subscribers?.length){
    load = true
    return addFields()
  }
  if(order.subscribers?.length > 0){
    if(subscribers.length < order.subscribers.length){
      order.subscribers.map(sub => {
        subscribers.push({document: sub.document, name: sub.name,  badge: sub.badge, whatsapp: sub.whatsapp, birth: sub.birth, email: sub.email, office: sub.office})
      })
      load = true
    }
  }else{
    if(subscribers.length == 0){
      load = true
      return addFields()
    }
  }
}
},[])


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Adicionar Inscritos
      </Typography>
      <Formik
        initialValues={{subscribers}}
        validationSchema={subscriberValidate}
        validateOnChange={true}
        validateOnMount={false}
        enableReinitialize
        onSubmit={values => {
          setSubscriber(values.subscribers)
          setOrder({...order, subscribers: values.subscribers})
          setActiveStep(activeStep+1)
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, isValid, setFieldValue, setFieldError }) => (
          <Form  autoComplete="off">
            <FieldArray name="subscribers">
              {({ push, remove }) => 
              (
                <div>
                  {values?.subscribers?.map((p, index) => {
                    const document = `subscribers[${index}].document`;
                    const toucheddocument = getIn(touched, document);
                    const errordocument = getIn(errors, document);

                    const badge = `subscribers[${index}].badge`;
                    const touchedbadge = getIn(touched, badge);
                    const errorbadge = getIn(errors, badge);

                    const name = `subscribers[${index}].name`;
                    const touchedname = getIn(touched, name);
                    const errorname = getIn(errors, name);

                    const email = `subscribers[${index}].email`;
                    const touchedemail = getIn(touched, email);
                    const erroremail = getIn(errors, email);

                    const birth = `subscribers[${index}].birth`;
                    const touchedbirth = getIn(touched, birth);
                    const errorbirth = getIn(errors, birth);

                    const office = `subscribers[${index}].office`;
                    const touchedoffice = getIn(touched, office);
                    const erroroffice = getIn(errors, office);

                    const whatsapp = `subscribers[${index}].whatsapp`;
                    const touchedwhatsapp = getIn(touched, whatsapp);
                    const errorwhatsapp = getIn(errors, whatsapp);
                  
                    return (
                      <React.Fragment key={index+1}>
                      <Typography variant="subtitle2">Inscrito N. {index+1}</Typography>
                      <Grid container columns={12} spacing={1} key={p.id}>
                        <Grid item xs={12} md={2}>
                        <TextField
                          id={name}
                          variant="outlined"
                          label="Nome Completo"
                          name={name}
                          fullWidth
                          value={p.name}
                          helperText={
                            touchedname && errorname
                              ? errorname
                              : ""
                          }
                          error={Boolean(touchedname && errorname)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        </Grid>
                        <Grid item xs={12} md={1}>
                        <TextField
                          id={badge}
                          variant="outlined"
                          label="Nome p/ Cracha"
                          name={badge}
                          fullWidth
                          value={p.badge}
                          helperText={
                            touchedbadge && errorbadge
                              ? errorbadge
                              : ""
                          }
                          error={Boolean(touchedbadge && errorbadge)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        </Grid>
                        <Grid item xs={12} md={2}>
                              <TextField
                                  id={document}
                                  name={document}
                                  label="CPF"
                                  fullWidth
                                  variant="outlined"
                                  inputProps={{ maxLength: 14 }}
                                  value={p.document}
                                  onChange={(e) => { 
                                      let cpf = maskDocument(e)
                                      setFieldValue(document,cpf)
                                    }
                                  }
                                  helperText={toucheddocument && errordocument ? errordocument : ""}
                                  error={toucheddocument && Boolean(errordocument)}
                                />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                                  id={email}
                                  label="Email"
                                  name={email}
                                  fullWidth
                                  value={p.email}
                                  onChange={handleChange}
                                  helperText={
                                    touchedemail && erroremail
                                      ? erroremail
                                      : ""
                                  }
                                  error={Boolean(touchedemail && erroremail)}
                                  variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                                  id={birth}
                                  label="Data de Nascimento"
                                  name={birth}
                                  fullWidth
                                  type='date'
                                  value={p.birth}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={handleChange}
                                  helperText={
                                    touchedbirth && errorbirth
                                      ? errorbirth
                                      : ""
                                  }
                                  error={Boolean(touchedbirth && errorbirth)}
                                  variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                  id={whatsapp}
                                  label="Whatsapp"
                                  fullWidth
                                  name={whatsapp}
                                  value={p.whatsapp}
                                  onChange={(e) => {
                                      let whats = maskTEL(e.target.value)
                                      setFieldValue(whatsapp,whats)
                                    }
                                  }
                                  helperText={
                                    touchedwhatsapp && errorwhatsapp
                                      ? errorwhatsapp
                                      : ""
                                  }
                                  error={Boolean(touchedwhatsapp && errorwhatsapp)}
                                  variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={12} md={1}>
                                <TextField
                                    id={office}
                                    label="Cargo ou Função"
                                    fullWidth
                                    name={office}
                                    value={p.office}
                                    onChange={handleChange}
                                    helperText={
                                      touchedoffice && erroroffice
                                        ? erroroffice
                                        : ""
                                    }
                                    error={Boolean(touchedoffice && erroroffice)}
                                    variant="outlined"
                                  />
                        </Grid>
                      </Grid>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </FieldArray>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button  
              variant="contained"
              type={'button'}
              onClick={() => setActiveStep(activeStep-1)}
              sx={{ mt: 3, ml: 1 }}>
               Voltar
           </Button>
           <Button 
              variant="contained"
              type='submit'
              sx={{ mt: 3, ml: 1 }}>
                  Revisar Inscrição
            </Button>
        </Box>
            </Form>
        )}
      </Formik>
      
    </React.Fragment>
  );
}
import * as React from 'react';
import { Typography, FormControl, FormHelperText,TextField, MenuItem, Select, InputLabel, Grid, Box, Button } from '@mui/material';
import { FormContext } from '../../../contexts/FormContext';
import { OrderContext } from '../../../contexts/OrderContext';
import { useEvent } from '../../../contexts/EventContext';
import { useFormik } from 'formik';
import * as yup from 'yup';

export default function TypeSubcriber() {
   const { activeStep, setActiveStep } = React.useContext(FormContext);
   const { order, setOrder } = React.useContext(OrderContext);
   const { events } = useEvent();
   const paymentOptions = ['Boleto', 'Pix','Transação ou Depósito Bancário']
   const [isFree, setIsFree ] = React.useState(false)

   const orderValidation = yup.object({
    plan: yup
        .number()
        .min(1,'Nenhum tipo de inscrição selecionado')
        .required('Campo Necessário'),    
    plan_describe: yup
        .string(),
    plan_price: yup
        .string(),
    quantity: yup
        .number()
        .positive('Quantidade deve ser positiva')
        .min(1,'1 é o mínimo de inscrições')
        .required('Quantidade necessária'),
    payment_form: yup
        .string()
        .required('Selecione a forma de pagamento!')
  })

   const typeValidation = useFormik({
    initialValues: {  
      quantity: order.quantity || 0,
      plan: order.plan || "",
      plan_describe: order.plan_describe || "",
      plan_price: order.plan_price || "",
      payment_form: "" ,
    },
    validationSchema: orderValidation,
    onSubmit: values => {
      console.log(values)
      setOrder({...order,...values})
      setActiveStep(activeStep + 1)
    },
    validateOnMount: true,
    enableReinitialize:true
   })

   React.useLayoutEffect(()=> {
      setIsFree(typeValidation.values.plan_price == 0)
   },[])

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Tipo e Quantidade de inscrições
      </Typography>
      <form onSubmit={typeValidation.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={isFree ? 6 : 6}>
            <FormControl fullWidth>
                <InputLabel id="plan">Tipo de Inscrição</InputLabel>
                <Select
                  labelId="plan"
                  //defaultValue={typeValidation.values.plan}
                  //value={typeValidation.values.plan}
                  label="Tipo de Inscrição"
                  error={typeValidation.touched.plan && typeValidation.errors.plan}
                  helperText={typeValidation.touched.plan && typeValidation.errors.plan}
                  onChange={(e,name) => { 
                    typeValidation.setFieldValue('plan',e.target.value)
                    name.props.price == 0 ? typeValidation.setFieldValue('payment_form', 'Gratuito') && setIsFree(true) : typeValidation.setFieldValue('payment_form', 'Pix') && setIsFree(false)
                    typeValidation.setFieldValue('plan_describe',name.props.name)
                    typeValidation.setFieldValue('plan_price',name.props.price)
                    }}
                  >
                  {
                    events.plans.map(plan => <MenuItem value={plan.id} name={plan.type} price={plan.price}>{plan.type} - R${plan.price}</MenuItem> )
                  }    
                </Select>
                <FormHelperText>{}</FormHelperText>
            </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
            <InputLabel id="payment_form">Forma de Pagamento</InputLabel>
            <Select
                id="payment_form"
                name="payment_form"
                label="Forma de Pagamento"
                fullWidth
                value={typeValidation.values.payment_form}
                onChange={typeValidation.handleChange}
                error={typeValidation.touched.payment_form && Boolean(typeValidation.errors.payment_form)}
                helperText={typeValidation.touched.payment_form && typeValidation.errors.payment_form}
                >
                <MenuItem disabled value={"Gratuito"} sx={{ display: isFree ? 'block' : 'none'}}>Gratuito</MenuItem>
                {!!events.payment_options && 
                Object.keys(events.payment_options).map((form) => { 
                  if(events.payment_options[form]){
                    return(
                      <MenuItem value={form} sx={{ display: events.payment_options[form] ? 'block' : 'none' }} >{form}</MenuItem>
                  )
                  }

                })
                }
                {!events.payment_options && 
                  paymentOptions.map((form) => {
                      return(
                          <MenuItem value={form}>{form}</MenuItem>
                      )
                  })
                }
            </Select>
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} md={3}>
        <TextField
            name="quantity"
            label="Quantidade de inscrições"
            fullWidth
            type={'number'}
            variant="outlined"
            id="quantity"
            value={typeValidation.values.quantity}
            onChange={typeValidation.handleChange}
            error={typeValidation.touched.quantity && Boolean(typeValidation.errors.quantity)}
            helperText={typeValidation.touched.quantity && typeValidation.errors.quantity}
            />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      variant="contained"
                      type={'button'}
                      onClick={() => setActiveStep(activeStep-1)}
                      sx={{ mt: 3, ml: 1 }}>
                      Voltar
                    </Button>

                    <Button
                      variant="contained"
                      type={'submit'}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      Próximo
                    </Button>
                  </Box>
      </form>
    </React.Fragment>
  );
}
import { useAuth } from "../contexts/auth.context";
import { useLocation, Navigate } from "react-router-dom";
import { getPermissions } from "../services/auth";


export default function RequireAuth({ children }) {
  let { signed } = useAuth();
  let location = useLocation();
  const permissions = JSON.parse(getPermissions())

  if (!signed) {
    return <Navigate to={`/`} state={{ from: location }} replace />;
  }
  if (children.props.title == undefined || children.props.title.length == 0) return children
  if (permissions && permissions[children.props.title]) return children;
  return <Navigate to={`/Dashboard`} state={{ from: location }} replace />;
}
/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from '../../contexts/auth.context';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect } from 'react';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
       {' Todos os diretos reservados ©  '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
function Login() {
  const CustomAlert = withReactContent(Swal)
  const { Login } = useAuth();
  const navigate = useNavigate();
  const storagedUser = sessionStorage.getItem('@EVAPI:user');
  const storagedToken = sessionStorage.getItem('@EVAPI:token');

  useEffect(() => {
    document.title = "Portal de eventos"
    let history = localStorage.getItem('back')
    if(storagedToken && storagedUser) {
      history ? navigate(history) : navigate("dashboard")
    }
  },[])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    try{
     const User = await Login({identifier: email, password: password})
     const firstScreen = Object.keys(User.data.user.permissions).filter((el,index) => Object.values(User.data.user.permissions)[index] && el !== "Logout" ? el : null)[0]
     const access = User.data.user.permissions['Dashboard'] === true ? 'dashboard' : `${firstScreen}`
      navigate(access)
    }catch(erro){
      console.error(erro)
      if(erro.code === "ERR_NETWORK"){
        CustomAlert.fire({
          title: "Erro ao conectar",
          icon: "error",
          confirmButtonColor: '#3085d6',
        });
      }else{
        CustomAlert.fire({
          title: "Erro!",
          text: "Credenciais inválidas",
          icon: "error",
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Tentar Novamente'
        });
    }
  }
  }
  return (
    <div style={{display:'flex',flex:1, height: '100vh', justifyContent:"center", alignItems:"center"}}>
    <Container component="main" maxWidth="xs" >
        <Box
        alignContent="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
             Entrar
            </Button>
            <Grid container>
              <Grid item xs >
                <Link href="#" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      </div>
  );
}

export default Login;

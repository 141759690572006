import React, { useEffect, useRef, useState } from 'react'
import {
    Stack, Typography, Box, Grid, Pagination, MenuItem, Select, FormControl, InputLabel,
    Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button, TextField,
    CircularProgress, Snackbar, Autocomplete, Card, CardContent, Chip
} from '@mui/material'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import MuiAlert from '@mui/material/Alert'
import * as qs from 'qs';
import { useEvent } from '../../contexts/event.context'
import { useNavigation } from '../../contexts/navigation.context'
import { useAuth } from "../../contexts/auth.context";
import { useNavigate } from 'react-router-dom';
import TableCollapsible from '../../components/TableCollapsible'
import { format, parseISO } from 'date-fns'
import { maskCNPJ } from '../../utils/mask'
import api from '../../services/api'
import XLSX from 'xlsx'

export default function Financeiro() {
    const [orders, setOrders] = useState([])
    const [dashData, setDataDash] = useState([])
    const navigation = useNavigation()
    const navigate = useNavigate()
    const [companies, setCompanies] = useState([])
    const [pagination, setPagination] = useState([])
    const page = useRef(1)
    const { events } = useEvent()
    const [selectedEvent, setSelectedEvent] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [selectedCompanyDocument, setSelectedCompanyDocument] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState()
    const [statusUpdate, setStatusUpdate] = useState('')
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();
    const [listCategories, setListCategory] = useState([]);
    const [loadingCompany, setLoadingCompany] = useState(false)
    const [search, setSearch] = useState(false)
    const [renderExcel, setRenderExcel] = useState(false)
    const [stateAlert, setStateAlert] = React.useState({ status: false, type: 'success', msg: '' });
    const query = useRef()
    const [timer, setTimer] = useState(null)
    const searchCompany = useRef()

    query.current = qs.stringify({
        filters: {
            deleted: {
                $eq: false
            }
        },
        sort: ['createdAt:desc'],
        populate: '*',
        pagination: {
            pageSize: 10,
            page: page.current,
        },
        encodeValuesOnly: true,

    })
    const status = ['AGUARDANDO PAGAMENTO', 'PAGO', 'CANCELADA', 'ATRASADA', 'RECLAMADA', 'REEMBOLSADA'];


    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    const columnsOrders = [
        { Header: "N. Pedido", accessor: "transaction" },
        { Header: 'Evento', accessor: 'event' },
        { Header: "Instituição", accessor: "social_reason" },
        { Header: "Status", accessor: "status" },
        { Header: "Tipo de Inscrição", accessor: "plan" },
        { Header: "Forma de Pagamento", accessor: "payment_form" },
        { Header: "Quant.", accessor: "quantity" },
        { Header: "Total", accessor: "total" },
        { Header: "Data do Pedido", accessor: "createdAt" },
        { Header: "Ações", accessor: "actions" },
    ];

    async function handleExcel() {
        if (orders.length <= 0) {
            setStateAlert({ ...stateAlert, status: true, type: 'warning', msg: `Nenhum resultado para gerar planilha!` })
            return
        }
        setRenderExcel(true)
        let params = {
            sort: ['createdAt:desc'],
            pagination: {
                limit: -1
            },
            filters: {
                deleted: {
                    $eq: false
                },
                event: {
                    name:  selectedEvent
                },
                plan: {
                    type: {
                        $contains: selectedCategory
                    }
                },
                company: {
                    social_reason: {
                        $contains: selectedCompany
                    },
                    document: {
                        $containsi: selectedCompanyDocument
                    }
                },
                status: {
                    $contains: selectedStatus
                },

            },
            populate: {
                event: {
                    fields: ['name']
                },
                plan: {
                    populate: '*',
                },
                company: {
                    populate: '*'
                },
                subscribers: {
                    populate: '*'
                }

            },
            encodeValuesOnly: true
        }
        query.current = qs.stringify(params, {
            skipNulls: false,
            skipUndefined: false,
            skipEmptyStrings: true
        })

        const dataSheet = await api.get(`/orders/?${query.current}`)
        let sheetData = []
        dataSheet.data.data.map(order => {
            sheetData.push({
                id: order.attributes.transaction,
                status: order.attributes.status,
                inscritos: order.attributes.quantity,
                instituicao: order.attributes.company.data.attributes.social_reason,
                estado: order.attributes.company.data.attributes.state,
                cidade: order.attributes.company.data.attributes.city,
                cnpj: order.attributes.company.data.attributes.document,
                pagamento: order.attributes.payment_form,
                categoria: order.attributes.plan.data.attributes.type,
                total: order.attributes.total,
                data_de_inscricao: format(parseISO(order.attributes.createdAt), 'dd/MM/yy HH:mm'),
            })
            return;
        })
        const sheet = XLSX.utils.json_to_sheet(sheetData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, sheet, 'inscricoes');
        setRenderExcel(false)
        return await XLSX.writeFile(workBook, 'inscricoes.xlsx')
    }

    function toPayment() {
            navigation.setPage(`/financeiro/payment`)
            navigation.setLink(`/financeiro/payment`)
            navigate(`/financeiro/payment`)
    }

    async function deleteOrder() {
        const id = selectedOrder.id
        try {
            const orderDelete = await api.delete('/orders/' + id)
            if (orderDelete.status === 200) {
                setStateAlert({ ...stateAlert, status: true, type: 'success', msg: `Inscrição deletada com sucesso` })
                setDeleteStatus(false)
                await handleSearch({ event: selectedEvent, status: selectedStatus, company: selectedCompany })
            }
        } catch (error) {
            console.log(error)
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `${error.response.data.error.message || error.message}` })
        }
    }

    async function handleSearch({ event, status, company, document, category }) {

        setSelectedEvent(event)
        setLoading(true)
        setSelectedStatus(status)
        setSelectedCompany(company)
        setSelectedCompanyDocument(document)

        let params = {
            sort: ['createdAt:desc'],
            filters: {
                event: {
                    name: event,
                    deleted: false
                },
                company: {
                    social_reason: {
                        $contains: company
                    },
                    document: {
                        $containsi: document
                    }
                },
                plan: {
                    type: {
                        $contains: category
                    }
                },
                status: {
                    $contains: status
                },
                deleted: {
                    $eq: false
                },
            },
            populate: {
                event: {
                    fields: ['name']
                },
                plan: {
                    populate: '*',
                },
                company: {
                    populate: '*'
                },
                subscribers: {
                    populate: '*'
                }

            },
            pagination: {
                pageSize: 10,
                page: page.current,
            },
            encodeValuesOnly: true
        }
        query.current = qs.stringify(params, {
            skipNulls: true,
            skipUndefined: true,
            skipEmptyStrings: true
        })
        try {
            const allOrders = await api.get(`/orders?${query.current}`)
            setPagination(allOrders.data.meta.pagination)
            let subs = []
            if (allOrders.status === 200) {
                subs = [...allOrders.data.data]
                // eslint-disable-next-line array-callback-return
                subs.map((subscriber, index) => {
                    subs[index].attributes.event = subs[index].attributes.event.data.attributes.name
                    subs[index].attributes.plan = subs[index].attributes.plan.data.attributes.type
                    subs[index].attributes.social_reason = subs[index].attributes.company.data.attributes.social_reason
                    subs[index].attributes.createdAt = format(parseISO(subs[index].attributes.createdAt), 'dd/MM/yy HH:mm')
                    subs[index].attributes.total = subs[index].attributes.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                })
                setOrders([...subs])
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function updateOrder() {
        const id = selectedOrder.id
        try {
            const newOrder = await api.put('/orders/' + id, { data: { status: statusUpdate.trim() } })
            if (newOrder.status === 200) {
                await handleSearch({ event: selectedEvent, status: selectedStatus, company: selectedCompany })
                setStateAlert({ ...stateAlert, status: true, type: 'success', msg: `Inscrição atualizada com sucesso!` })
                setDialogOpen(false)
            }
        } catch (error) {
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `${error.response.data.error.message || error.message}` })
        }
    }

    function handleEdit(order) {
        setSelectedOrder({ ...order })
        setDialogOpen(true)
    }

    function handleDelete(order) {
        setSelectedOrder({ ...order })
        setDeleteStatus(true)
    }

    async function handlePayment(order) {
        if (order.attributes.payment_url !== null) {
            window.open(order.attributes.payment_url)
        }
    }

    const inputCompany = e => {
        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            setLoadingCompany(true)
            loadCompany(e.target.value)
        }, 500)

        setTimer(newTimer)
    }

    async function loadCompany(company) {
        searchCompany.current = qs.stringify({
            filters: {
                social_reason: {
                    $containsi: company
                }
            },
            sort: ['social_reason:asc'],
            populate: '*',
            encodeValuesOnly: true,
        })
        try {
            const allCompanies = await api.get('/companies?' + searchCompany.current)
            setCompanies([...allCompanies.data.data])
            setLoadingCompany(false)
        } catch (error) {
            setLoadingCompany(false)
            setStateAlert({ ...stateAlert, status: true, type: 'error', msg: `${error.message}` })
        }
    }

    const inputCompanyDocument = e => {
        if (e.target.value.length > 18) { return }
        if (e.target.value.length > 0) { return setSelectedCompanyDocument(maskCNPJ(e.target.value)) }
        return setSelectedCompanyDocument(e.target.value)
    }

    async function loadCardsbyEvent() {
        setDataDash([])
        let dashQuery = {
            filters: {
                event: {
                    name: {
                        $contains: selectedEvent
                    },
                    deleted: false
                },
                deleted: false
            },
            populate: {
                event: {
                    fields: ['name']
                },
                plans: true
            },
            encodeValuesOnly: true
        }
        let query = qs.stringify(dashQuery, {
            skipNulls: true,
            skipUndefined: true,
            skipEmptyStrings: true
        })
        const dashData = await api.get(`/order/dashboard?${query}`)
        setDataDash(dashData.data)

    }

    useEffect(() => {
        if (selectedEvent && selectedEvent?.length > 0) {
            loadCardsbyEvent()
            setSelectedCategory("")
            events.map(evento => {
                if (evento.attributes.name == selectedEvent) {
                    return setListCategory(evento.attributes.plans.data)
                }
            })
        } else {

            setSelectedCategory("")
            setListCategory([])
            setDataDash([])
        }
    }, [selectedEvent])


    return (
        <Box sx={{ width: '100%', height: '100vh' }} p={3}>
            <Snackbar
                open={stateAlert.status}
                autoHideDuration={2500}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setStateAlert({ status: false, type: 'success', msg: '' })} >
                <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                    {stateAlert.msg}
                </Alert>
            </Snackbar>
            <Stack direction="column" alignItems="flex-start" justifyContent={'flex-start'} spacing={2} mx={4}>
                <Typography variant='h4'>Pedidos de inscrição</Typography>
                <Grid container direction='row' spacing={2} width={'100%'} py={3}>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '120px', backgroundColor: '#F3F3F3' }}>
                            <Box>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Box py={1} sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Box px={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: 50, backgroundColor: '#0093E9', backgroundImage: 'linear-gradient(45deg, #0093E9 0%, #80D0C7 100%)' }}>
                                            <RequestQuoteIcon />
                                        </Box>
                                        <Typography py={1} px={2} sx={{ fontSize: 18, fontWeight: 400 }} component="div">
                                            Cobranças
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flex: '1 0 auto', justifyContent: 'space-evenly' }}>
                                        {dashData.length > 0 &&
                                            <>
                                                <Chip label={`${dashData.filter(pay => pay.status == 'PAGO')[0].qt} PAGAS`} variant="outlined" color="success" />
                                                <Chip label={`${dashData.filter(pay => pay.status == 'AGUARDANDO PAGAMENTO')[0].qt} EM DIA`} variant="outlined" color="info" />
                                                <Chip label={`${dashData.filter(pay => pay.status == 'ATRASADA')[0].qt} ATRASADAS`} variant="outlined" color="error" />
                                            </>
                                        }
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '120px', backgroundColor: '#F3F3F3' }}>
                            <Box >
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Box py={1} sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Box px={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: 50, backgroundColor: '#FAD961', backgroundImage: 'linear-gradient(45deg, #FAD961 0%, #F76B1C 100%)' }}>
                                            <EqualizerIcon />
                                        </Box>
                                        <Typography py={1} px={2} sx={{ fontSize: 18, fontWeight: 400 }}>
                                            Faturamento
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', justifyContent: 'space-evenly' }}>
                                        {dashData.length > 0 &&
                                            <>
                                                <Chip label={`${dashData[0].ordersWait > 0 ? dashData[0].ordersWait.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'} PREVISTAS`} variant="outlined" color="info" />
                                                <Chip label={`${dashData[0].ordersPayed > 0 ? dashData[0].ordersPayed.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'} RECEBIDO`} variant="outlined" color="success" />
                                            </>
                                        }
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container direction='row' spacing={2} width={'100%'} py={1}>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth >
                            <InputLabel id="select-event">Selecionar Evento:</InputLabel>
                            <Select
                                labelId="select-event"
                                id="select-event"
                                value={selectedEvent}
                                onChange={(event) => {
                                    page.current = 1
                                    setSelectedCategory("")
                                    handleSearch({ event: event.target.value, status: selectedStatus, company: selectedCompany, document: selectedCompanyDocument, category: selectedCategory })
                                }
                                }
                                label="Selecionar Evento"
                            >
                                <MenuItem value="">
                                    <em>Nenhum</em>
                                </MenuItem>
                                {events.map(evento =>
                                    <MenuItem key={evento.id} value={evento.attributes.name}>{evento.attributes.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth >
                            <InputLabel id="select-status">Selecionar Status:</InputLabel>
                            <Select
                                labelId="select-status"
                                id="select-status"
                                value={selectedStatus}
                                onChange={(event) => handleSearch({ event: selectedEvent, status: event.target.value, company: selectedCompany, document: selectedCompanyDocument, category: selectedCategory })}
                                label="Selecionar Status"
                            >
                                <MenuItem value="">
                                    <em>Nenhum</em>
                                </MenuItem>
                                {status.map((status, index) =>
                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="asynchronous-companies"
                                sx={{ width: "100%" }}
                                open={search}
                                onOpen={() => {
                                    setSearch(true);
                                }}
                                onClose={() => {
                                    setSearch(false);
                                }}
                                isOptionEqualToValue={(option, value) => option.attributes.social_reason === value.attributes.social_reason}
                                onSelect={(e) => setSelectedCompany(e.target.value)}
                                getOptionLabel={(option) => option.attributes.social_reason}
                                options={companies}
                                freeSolo
                                autoComplete
                                autoHighlight
                                loading={loadingCompany}
                                renderInput={(params) => (
                                    <TextField
                                        label="Selecionar Instituição"
                                        {...params}
                                        onChange={inputCompany}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loadingCompany ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth>
                            <TextField
                                label="Pesquisar CNPJ:"
                                variant="outlined"
                                labelId="select-document"
                                value={selectedCompanyDocument}
                                onChange={inputCompanyDocument}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth >
                            <InputLabel id="select-category">Selecionar Categoria:</InputLabel>
                            <Select
                                labelId="select-category"
                                id="select-category"
                                value={selectedCategory}
                                onChange={(event) => {
                                    setSelectedCategory(event.target.value);
                                    handleSearch({ event: selectedEvent, status: selectedStatus, company: selectedCompany, document: selectedCompanyDocument, category: event.target.value })
                                }
                                }
                                label="Selecionar Categoria"
                            >
                                <MenuItem value="">
                                    <em>Nenhum</em>
                                </MenuItem>
                                {listCategories.length > 0 && listCategories.map(plan =>
                                    <MenuItem key={plan.id} value={plan.attributes.type}>{plan.attributes.type}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Stack direction='row' spacing={8} width={"100%"} alignItems={"flex-end"} justifyContent={'flex-end'}>
                    <Button sx={{ width: "20%" }} variant='contained' color='info' onClick={() => handleSearch({ event: selectedEvent, status: selectedStatus, company: selectedCompany, document: selectedCompanyDocument, category: selectedCategory })}>Pesquisar</Button>
                    <Button sx={{ width: "20%" }} variant='contained' color='success' onClick={() => handleExcel()} disabled={renderExcel}>
                        {renderExcel ? <CircularProgress sx={{ alignSelf: 'center' }} size={20} /> : "Exportar para Excel"}
                    </Button>
                    <Button sx={{ width: "20%" }} variant='contained' color='warning' onClick={toPayment}>Gerar cobrança</Button>
                </Stack>
            </Stack>
            {loading ?
                <Box sx={{ width: '100%', height: '10vh', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress sx={{ alignSelf: 'center' }} size={50} />
                </Box>
                :
                <>
                    <Grid container py={3} width={"100%"}>
                        <TableCollapsible title='Inscrições' variant="secondary" edit={handleEdit} deactive={handleDelete} payment={handlePayment} rows={orders} columns={columnsOrders} />
                    </Grid>
                    <Stack direction="row" alignItems="center" justifyContent={'flex-end'} spacing={2} mx={10}>
                        <Pagination count={pagination.pageCount} defaultPage={1} color="primary" page={page.current}
                            onChange={(event, value) => {
                                page.current = value
                                handleSearch({ event: selectedEvent, status: selectedStatus, company: selectedCompany, document: selectedCompanyDocument })
                            }} />
                    </Stack>
                </>
            }
            <Dialog
                open={dialogOpen}
                aria-describedby="edit-dialog" >
                <DialogTitle>{"Editar Inscrição"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={6} py={2} >
                        <Grid item sm={12}>
                            <TextField sx={{ width: '100%' }}
                                label="Inscrição"
                                id='transaction'
                                name="transaction"
                                disabled
                                variant="outlined"
                                value={selectedOrder ? selectedOrder.attributes.transaction : ''}
                                placeholder="Inscrição" />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField sx={{ width: '100%' }}
                                label="Instituição"
                                id='company'
                                name="company"
                                disabled
                                value={selectedOrder ? selectedOrder.attributes.company.data.attributes.social_reason.toUpperCase() : ''}
                                variant="outlined"
                                placeholder="Instituição" />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField sx={{ width: '100%' }}
                                label="Nome Fantasia"
                                id='trade'
                                name="trade"
                                disabled
                                value={selectedOrder ? selectedOrder.attributes.company.data.attributes.trade.toUpperCase() : ''}
                                variant="outlined"
                                placeholder="Nome Fantasia" />
                        </Grid>
                        <Grid item sm={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="selected-status">Alterar Status:</InputLabel>
                                <Select
                                    labelId="selected-status"
                                    id="selected-status"
                                    disabled={(selectedOrder && selectedOrder.attributes.payment_form == 'Boleto')}
                                    onChange={(event) => setStatusUpdate(event.target.value)}
                                    value={statusUpdate || (selectedOrder && selectedOrder.attributes.status)}
                                    label="Alterar Status"
                                >
                                    {status.map((status, index) =>
                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setDialogOpen(false)} color="error">Cancelar</Button>
                    <Button variant='outlined' onClick={() => updateOrder()} color="success">Atualizar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteStatus}
                aria-describedby="edit-dialog" >
                <DialogTitle>{"Deletar Pedido de Inscrição"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Deseja mesmo deletar o pedido de inscrição?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setDeleteStatus(false)} color="warning">Cancelar</Button>
                    <Button variant='outlined' onClick={() => deleteOrder()} color="error">Deletar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
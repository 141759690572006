import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const updateFavicon = (iconPath) => {
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
        link.href = iconPath;
    } else {
        const newLink = document.createElement("link");
        newLink.rel = "icon";
        newLink.href = iconPath;
        document.head.appendChild(newLink);
    }
};

const FaviconUpdater = () => {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split("/")[1];
        console.log(location, path, path === "agip");
        if(path === 'agip') {
                updateFavicon("/favicons/agip.ico");
        }else if( path === "apeprev"){
                updateFavicon("/favicons/apeprev.ico");
        }
    }, [location.pathname]);

};

export default FaviconUpdater;

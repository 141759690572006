import React, { useEffect, useRef, useState } from "react"
import { Box, CssBaseline, Grid, CircularProgress } from "@mui/material"
import { useNavigation } from '../../contexts/navigation.context'
import { useNavigate } from 'react-router-dom';
import api from '../../services/api'
import * as qs from 'qs';

import CardInfo from '../../components/CardInfo'
import TableInfo from '../../components/TableInfo'
import EventNote from '@mui/icons-material/EventNote'
import PeopleOutline from '@mui/icons-material/PeopleOutline'

import { useEvent } from '../../contexts/event.context';
import { useAuth } from "../../contexts/auth.context";



export default function Dashboard() {
    const navigation = useNavigation()
    const navigate = useNavigate()
    const [subscribers, setSubscribers] = useState([])
    const totalSubscribers = useRef(0)
    const [loading, setLoading] = useState(true)
    const { basePath } = useAuth()
    const { events, getEvents, total } = useEvent()

    const columns = [
        { Header: "Evento", accessor: "name", width: "25%" },
        { Header: "Cidade", accessor: "city" },
        { Header: "Inscritos", accessor: "count_subscribers", width: "30%" },
        { Header: "Máximo de Inscritos", accessor: "max_subscribers", width: "30%" },
        { Header: "Início do Evento", accessor: "date_initial", width: "12%" }];

    const columnsSubscribers = [
        { Header: "Evento", accessor: "event", width: "25%" },
        { Header: "Insituição", accessor: "company", width: "20%" },
        { Header: "Nome", accessor: "name", width: "20%" },
        { Header: "CPF", accessor: "document" },
        { Header: "Cidade", accessor: "locale", width: "20%" },
        { Header: "Whatsapp", accessor: "whatsapp", width: "12%" },
    ];

    async function handleSubscribers() {
        let query = qs.stringify({
            filters: {
                deleted: {
                    $eq: false
                }
            },
            sort: ['createdAt:desc'],
            populate: '*',
            pagination: {
                pageSize: 10,
                page: 1,
            },
            encodeValuesOnly: true,
        })
        try {
            const allSubscribers = await api.get('/orders?' + query)
            let subs = []
            if (allSubscribers.status === 200) {
                subs = [...allSubscribers.data.data]
                console.log(subs)
                let subscribersOrder = []
                // eslint-disable-next-line array-callback-return
                subs.map((subscriber, index) => {
                    subs[index].attributes.subscribers.data.map(sub => {
                        sub.attributes.event = subs[index].attributes.event.data.attributes.name
                        sub.attributes.locale = subs[index].attributes.company.data.attributes.city
                        sub.attributes.company = subs[index].attributes.company.data.attributes.social_reason
                        return subscribersOrder.push(sub)
                    })

                })
                totalSubscribers.current = allSubscribers.data.meta.pagination.total
                console.log('order', subscribersOrder)
                setSubscribers([...subscribersOrder])
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        handleSubscribers()
        getEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleAction(page) {
            navigation.setPage(`/${page}`)
            navigation.setLink(`/${page}`)
            navigate(`/${page}`)
    }

    return (
        <Box sx={{ width: '100%', height: '100vh' }}>
            <CssBaseline />
            <>
                {loading ?
                    <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress sx={{ alignSelf: 'center' }} size={100} />
                    </Box>
                    : <>
                        <Grid container spacing={4} p={3} justifyContent="center" alignItems="center">
                            <Grid item xs={4}>
                                <CardInfo elevation={16} name='eventos' onClick={() => handleAction('eventos')} variant={'success'} title={'Eventos Cadastrados'} info={`${total.current || 0} Eventos`} icon={<EventNote fontSize="large" />} />
                            </Grid>
                            <Grid item xs={4}>
                                <CardInfo elevation={16} name='pedidos' onClick={() => handleAction('financeiro')} variant={'info'} title={'Pedidos de Inscrição'} info={`${totalSubscribers.current || 0} Pedidos`} icon={<PeopleOutline fontSize="large" />} />
                            </Grid>
                        </Grid>
                        <Grid container p={3}>
                            <TableInfo title='Últimos Eventos' variant="success" rows={events} columns={columns} />
                        </Grid>
                        <Grid container p={3}>
                            <TableInfo title='Últimos Inscritos' variant="info" rows={subscribers} columns={columnsSubscribers} />
                        </Grid>
                    </>
                }
            </>
        </Box>

    )
}